
<script setup>
    import { ref, computed } from "vue";
    import { MBottomSheet } from "o365-mobile";
    import CheckItemsSimple from "./CheckItems.CheckItemsSimple.vue";

    const props = defineProps({
        dataObject: { type: Object, required: true },
        readonly: { type: Boolean, required: false },
        itemId: { type: Number, required: false },
        stepId: { type: Number, required: false },
        sectionId: { type: Number, required: false },
        promotedProcessId: { type: Number, required: false },
        checkItems: { type: null, required: false },
    });

    const checkItemsStatus = computed(() => {
        const itemsTotal = props.dataObject.data.filter(e => !e.IsHeader);
        const itemsDone = itemsTotal.filter(e => (e.oldValues.Status ?? e.Status) !== -1);
        if (itemsDone.length < itemsTotal.length) {
            return `${itemsDone.length} / ${itemsTotal.length} ${$t("check items complete")}`;
        }

        return $t("All check items complete");
    });

    const showBottomSheet = ref(false);
</script>

<template>
    <div class="mt-2">
        <span>
            {{ $t("Checklist") }}: <span style="font-weight:600">{{ dataObject.state.isLoaded && dataObject.data.length > 0 ? dataObject.data[0].ChecklistName ?? $t("User Defined") : $t("Loading...") }}</span>
            <template v-if="dataObject.data.length > 0 && dataObject.data[0].ChecklistRevision != null">, {{ $t("Revision") }}: <span style="font-weight: 600;">{{ dataObject.data[0].ChecklistRevision }}</span></template>
        </span>
        <div class="mt-1">
            <button class="btn btn-outline-primary w-100 dropdown-toggle" @click="showBottomSheet = true">
                {{ checkItemsStatus }}
            </button>
        </div>
    </div>
    <MBottomSheet v-model="showBottomSheet" min-height="calc(70vh - 4rem)">
        <template #title>
            {{ $t("Checklist") }}
        </template>
        <template #body>
            <div class="p-3">
                <CheckItemsSimple :checkItems="checkItems" :dataObject="props.dataObject" :itemId="itemId" :stepId="stepId" :sectionID="sectionId" :promotedProcessId="promotedProcessId" />
            </div>
        </template>
    </MBottomSheet>
</template>
